import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

import { REACT_APP_API_URL } from "./config";

import Main from "./pages/Main";
import UserReport from "./pages/UserReport";
import Contacts from "./pages/Contacts";
import About from "./pages/About";
import ThankYou from "./pages/ThankYou";
import Choose from "./pages/Choose";

import { SiteDataProvider } from "./SiteDataContext";
import MobileMenu from "./components/MobileMenu";

function App() {
  const [siteData, setSiteData] = useState({});

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/api/site-texts/`)
      .then((response) => {
        setSiteData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  return (
    <SiteDataProvider siteData={siteData} setSiteData={setSiteData}>
      <Router>
        <MobileMenu />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/user-report/:type" element={<UserReport />} />
          <Route path="/choose" element={<Choose />} />
          <Route path="/thank-you/:reportId" element={<ThankYou />} />
        </Routes>
      </Router>
    </SiteDataProvider>
  );
}

export default App;
