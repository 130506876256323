import React from "react";
import { useSiteData } from "../SiteDataContext";

import closeIcon from "../assets/img/icons/closeIcon.svg";

function Modal() {
  const { siteData, setSiteData } = useSiteData();

  const close = () => {
    setSiteData({ ...siteData, isOpenPolicyModal: false });
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === "policy-modal") {
      close();
    }
  };

  return (
    <div
      id="policy-modal"
      className={`modal-container p-5 md:p-2 flex justify-center items-center ${
        siteData.isOpenPolicyModal ? "" : "hidden"
      }`}
      onClick={handleBackgroundClick}
    >
      <div className="overflow-auto relative w-100p h-100p md:mh-65-5 md:mw-60 bg-cream pl-1-25 br-1-25 md:plr-4-75">
        <h2 className="text-center f-size-2 font-bold mt-8 mb-3 ">
          {siteData?.policy?.title}
        </h2>

        <div
          className="inline absolute right-8 top-8 p-0-5 cursor-pointer"
          onClick={close}
        >
          <img className="w-1-125" src={closeIcon} />
        </div>

        <article className="f-size-1">
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{
              __html: siteData?.policy?.content,
            }}
          />
        </article>
      </div>
    </div>
  );
}

export default Modal;
