import React, { createContext, useContext } from "react";

const SiteDataContext = createContext();

export const SiteDataProvider = ({ children, siteData, setSiteData }) => {
  return (
    <SiteDataContext.Provider value={{ siteData, setSiteData }}>
      {children}
    </SiteDataContext.Provider>
  );
};

export const useSiteData = () => {
  return useContext(SiteDataContext);
};
