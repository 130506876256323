import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";

import iIcon from "../assets/img/icons/iIcon.svg";
import protocolsIcon from "../assets/img/icons/protocolsIcon.svg";
import mesIcon from "../assets/img/icons/mesIcon.svg";
import socIcon from "../assets/img/icons/socIcon.svg";
import striIcon from "../assets/img/icons/striIcon.svg";
import profIcon from "../assets/img/icons/profIcon.svg";
import otherIcon from "../assets/img/icons/otherIcon.svg";

function Hero() {
  const { siteData, setSiteData } = useSiteData();

  useEffect(() => {
    const iconElement = document.getElementById("iIcon");

    if (iconElement) {
      iconElement.addEventListener("click", handleIconClick);
    }

    return () => {
      if (iconElement) {
        iconElement.removeEventListener("click", handleIconClick);
      }
    };
  }, [siteData]);

  const handleIconClick = () => {
    setSiteData((prevData) => ({
      ...prevData,
      isOpenPolicyModal: true,
    }));
  };

  return (
    <main className="w-full">
      <section className="w-full flex justify-center">
        <div className="container pl-1-25 md:plr-3">
          <h2 className="f-size-2-5 lt-0-01 md:lt-0-04 fw-900 md:f-size-4 font-bold mt-6 md:mt-10-7 mb-2-5 md:mb-1-7">
            {siteData?.main?.title}
          </h2>
          <article className="md:w-41">
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: siteData?.main?.content,
              }}
            />
          </article>

          <div className="flex justify-center md:justify-start mb-7-5 md:mb-17-5">
            <Link
              to="/choose"
              className="br-0-5 main-button f-size-1-125 md:f-size-1-5 md:lt-0-02"
            >
              Сообщить о сбое
            </Link>
          </div>
        </div>
      </section>

      <section className="w-full flex justify-center">
        <div className="container pl-1-25 bg-cream-light br-t-1-25 md:br-1-25 w-full p-2-875 pt-2-5 md:pt-3-3 pb-3 md:plr-8">
          <h3 className="md:f-size-3-125 f-size-1-5 font-bold mb-1-5 md:mb-2-1 text-center md:lt-0-08">
            {siteData?.whatWeCollect?.title}
          </h3>

          <div
            className="ck-content"
            dangerouslySetInnerHTML={{
              __html: siteData?.whatWeCollect?.content,
            }}
          />

          <div className="grid grid-cols-2 cg-2 rg-3 md:rg-4-4 md:cg-9 md:grid-cols-3">
            <article>
              <img src={protocolsIcon} className="mb-0-75" />
              <h4 className="f-size-1 md:f-size-1-5 font-bold mb-0-75">
                Протоколы VPN
              </h4>
              <p className="f-size-0-875 md:f-size-1-125 lh-1 md:lh-1-375 md:lh-1-4">
                Работоспособность различных протоколов для обеспечения
                безопасности каналов связи
              </p>
            </article>

            <article>
              <img src={mesIcon} className="mb-0-75" />
              <h4 className="f-size-1 md:f-size-1-5 font-bold mb-0-75">
                Мессенджеры
              </h4>
              <p className="f-size-0-875 md:f-size-1-125 lh-1 md:lh-1-375 md:lh-1-4">
                Доступность популярных и альтернативных мессенджеров в России
              </p>
            </article>
            <article>
              <img src={socIcon} className="mb-0-75" />
              <h4 className="f-size-1 md:f-size-1-5 font-bold mb-0-75">
                Соцсети
              </h4>
              <p className="f-size-0-875 md:f-size-1-125 lh-1 md:lh-1-375 md:lh-1-4">
                Работоспособность отечественных и зарубежных социальных сетей
              </p>
            </article>
            <article>
              <img src={striIcon} className="mb-0-75" />
              <h4 className="f-size-1 md:f-size-1-5 font-bold mb-0-75">
                Стриминговые платформы
              </h4>
              <p className="f-size-0-875 md:f-size-1-125 lh-1 md:lh-1-375 md:lh-1-4">
                Доступность сервисов с медиа-контентом
              </p>
            </article>
            <article>
              <img src={profIcon} className="mb-0-75" />
              <h4 className="f-size-1 md:f-size-1-5 font-bold mb-0-75">
                Профессиональные инструменты
              </h4>
              <p className="f-size-0-875 md:f-size-1-125 lh-1 md:lh-1-375 md:lh-1-4">
                Функционирование различных инструментов, необходимых в рабочих и
                бизнес задачах
              </p>
            </article>
            <article>
              <img src={otherIcon} className="mb-0-75" />
              <h4 className="f-size-1 md:f-size-1-5 font-bold mb-0-75">
                Прочие сервисы
              </h4>
              <p className="f-size-0-875 md:f-size-1-125 lh-1 md:lh-1-375 md:lh-1-4">
                Исправность других важных для вас онлайн-сервисов
              </p>
            </article>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Hero;
