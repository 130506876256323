import React from "react";
import { useLocation } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";

import logo from "../assets/img/logo.svg";
import mobileMenuIcon from "../assets/img/icons/mobileMenuIcon.svg";

function Header() {
  const location = useLocation();
  const { pathname } = location;
  const { siteData, setSiteData } = useSiteData();

  return (
    <header className="w-full bg-header">
      <div className="container relative pl-1-25 md:plr-3 mx-auto w-full text-white h-3-5 md:h-5 flex md:justify-center">
        <div
          className="w-1/5 flex h-100p cursor-pointer absolute pl-1-25 top-0 left-0 md:hidden"
          onClick={() =>
            setSiteData({ ...siteData, isOpenMobileMenuModal: true })
          }
        >
          <img
            src={mobileMenuIcon}
            className="mw-2-625 mr-3 md:w-3-5 md:mr-1"
            alt="Logo"
          />
        </div>
        <div className="md:w-1/2 flex flex-1 items-center justify-center md:justify-start">
          <div className="cursor-pointer">
            <a href="/" className="flex items-center">
              <img
                src={logo}
                className="w-2-2 mr-3 md:w-3-5 md:mr-1"
                alt="Logo"
              />
              <h3 className="uppercase italic md:lt-0-03 f-size-1-25 md:f-size-1-9 font-bold font-HelveticaNeueLTW06107XBlkCnObl">
                Монитор рунета
              </h3>
            </a>
          </div>
        </div>
        <div className="md:w-1/2 flex hidden md:flex">
          <ul className="flex items-center justify-end w-full text-lg">
            <li>
              <a
                className={`py-1-5 px-4 f-size-1-125 font-bold ${
                  pathname === "/about" ? "color-green" : ""
                }`}
                href="/about"
              >
                О нас
              </a>
            </li>
            <li>
              <a
                className={`py-1-5 px-4 f-size-1-125 font-bold ${
                  pathname === "/contacts" ? "color-green" : ""
                }`}
                href="/contacts"
              >
                Контакты
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
