import Header from "../components/Header";
import { Link } from "react-router-dom";

function Choose() {
  return (
    <div className="main-body bg-cream md:pb-10">
      <Header />

      <main className="w-full">
        <section className="w-full flex justify-center">
          <div className="container pl-1-25 md:plr-3">
            <h2 className="text-center lt-0-03 f-size-1-5 fw-700 font-bold mb-2-5 mt-8 mb-6 md:lt-0-04 md:f-size-2-5 md:mt-5 md:mb-3-5">
              Что перестало работать?
            </h2>

            <div className="flex justify-center mb-3-5 md:mb-5">
              <Link
                to="/user-report/internet"
                className="text-center w-16-25 md:w-21-25 br-0-5 main-button bg-dark-green f-size-1-125 md:f-size-1-5 md:lt-0-02"
              >
                Связь/интернет
              </Link>
            </div>
            <div className="flex justify-center mb-3-5 md:mb-5">
              <Link
                to="/user-report/vpn"
                className="text-center w-16-25 md:w-21-25 br-0-5 main-button bg-dark-green f-size-1-125 md:f-size-1-5 md:lt-0-02"
              >
                VPN сервис
              </Link>
            </div>

            <div className="flex justify-center mb-3-5 md:mb-5">
              <Link
                to="/user-report/service"
                className="text-center w-16-25 md:w-21-25 br-0-5 main-button bg-dark-green f-size-1-125 md:f-size-1-5 md:lt-0-02"
              >
                Другие сервисы
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Choose;
