import Header from "../components/Header";
import Hero from "../components/Hero";
import Modal from "../components/Modal";

function Main() {
  return (
    <div className="main-body bg-cream md:pb-10">
      <Header />
      <Hero />
      <Modal />
    </div>
  );
}

export default Main;
